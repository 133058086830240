<template>
  <div class="container mt-5">
    <div class="row d-flex justify-content-center">
      <div class="col col-6">
        <div class="card">
          <div class="card-header d-flex justify-content-between">
            Admin actions
          </div>
          <div
            v-if="initialized"
            class="card-body w-100 p-5"
          >
            <div class="upload-container">
              <div
                v-for="serviceStatus in serviceStatuses"
                :key="serviceStatus.serviceName"
              >
                <h5>Service name: {{ serviceStatus.serviceName }}</h5>
                <br />
                <div class="form-check form-switch">
                  <input
                    v-if="services[serviceStatus.serviceName]"
                    id="flexSwitchCheckDefault"
                    v-model="services[serviceStatus.serviceName].hasErrors"
                    class="form-check-input"
                    type="checkbox"
                    role="switch"
                    @change="showServiceStatusConfirmationModal(serviceStatus.serviceName)"
                  />
                  <label
                    class="form-check-label"
                    for="flexSwitchCheckDefault"
                  >
                    Enable/disable customer portal error
                    notification for "{{ serviceStatus.serviceName }}" service
                  </label>
                </div>
                <hr />
                <TextInput
                  v-if="services[serviceStatus.serviceName]"
                  v-model="services[serviceStatus.serviceName].notificationMessage"
                  :errors="v$.services[serviceStatus.serviceName].notificationMessage.$errors"
                  label="Notification Message"
                  description="Put here the message you want to show users"
                  @blur="v$.services[serviceStatus.serviceName].notificationMessage.$touch"
                />
                <button
                  class="btn btn-sm btn-primary"
                  :disabled="v$.services[serviceStatus.serviceName].notificationMessage.$errors?.length > 0"
                  @click="showNotificationMessageConfirmationModal(serviceStatus.serviceName)"
                >
                  Update notification message
                </button>
                <hr />
              </div>
              <h5>Alerts to clients</h5>
              <div class="form-check form-switch">
                <input
                  v-if="alertsToClientsStatus"
                  v-model="alertsToClientsStatus.status"
                  class="form-check-input"
                  type="checkbox"
                  role="switch"
                  @change="showAlertsToClientsStatusConfirmationModal()"
                />
                <label
                  class="form-check-label"
                  for="flexSwitchCheckDefault"
                >
                  Enable/disable alerts to clients
                </label>
              </div>
            </div>
          </div>
          <Spinner v-else />
        </div>
      </div>
    </div>
  </div>
  <AdminActionsServiceStatusConfirmation
    ref="change-notification-mode-confirmation"
    :onCancelHandler="cancelEdit"
    :onConfirmHandler="saveErrorsStatusHandler"
  />
  <AdminActionsNotificationMessageConfirmation
    ref="change-notification-message-confirmation"
    :onCancelHandler="cancelEdit"
    :onConfirmHandler="saveNotificationMessageHandler"
    :displayedMessage="services[processedServiceName] ? services[processedServiceName].notificationMessage : ''"
  />
  <AdminActionsAlertsToClientsConfirmation
    ref="change-alerts-to-clients-confirmation"
    :onCancelHandler="cancelEdit"
    :onConfirmHandler="saveAlertsToClientsStatusHandler"
  />
</template>
<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { useVuelidate } from '@vuelidate/core';
import Spinner from '@/components/common/Spinner';
import TextInput from '@/components/common/TextInput';
import { maxLength } from '@vuelidate/validators';
import AdminActionsServiceStatusConfirmation from '@/components/AdminActions/AdminActionsServiceStatusConfirmation';
import AdminActionsNotificationMessageConfirmation from '@/components/AdminActions/AdminActionsNotificationMessageConfirmation';
import AdminActionsAlertsToClientsConfirmation from '@/components/AdminActions/AdminActionsAlertsToClientsConfirmation';

export default {
  components: {
    AdminActionsNotificationMessageConfirmation,
    Spinner,
    TextInput,
    AdminActionsServiceStatusConfirmation,
    AdminActionsAlertsToClientsConfirmation
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      initialized: false,
      generalNotificationsEnabled: false,
      services: { },
      alertsToClientsStatus: null,
      serviceStatusConfirmationModal: null,
      notificationMessageConfirmationModal: null,
      processedServiceName: null,
      alertsToClientsConfirmationModal: null
    };
  },
  validations() {
    const serviceNames = _.keys(this.services);

    return {
      services: _.reduce(
        serviceNames,
        (acc, serviceName) => {
          if (serviceName) {
            acc[serviceName] = {
              hasErrors: {},
              notificationMessage: {
                minLength: maxLength(180)
              }
            };
          }
          return acc;
        },
        {})
    };
  },
  computed: {
    ...mapGetters({
      serviceStatuses: 'adminActions/serviceStatuses'
    })
  },
  async mounted() {
    this.serviceStatusConfirmationModal = this.$refs['change-notification-mode-confirmation'].confirmationModal;
    this.notificationMessageConfirmationModal = this.$refs['change-notification-message-confirmation'].confirmationModal;
    this.alertsToClientsConfirmationModal = this.$refs['change-alerts-to-clients-confirmation'].confirmationModal;
    this.initialized = false;
    await this.init();
  },
  methods: {
    ...mapActions({
      changeServiceStatus: 'adminActions/changeServiceStatus',
      changeServiceNotificationMessage: 'adminActions/changeServiceNotificationMessage',
      fetchServiceStatuses: 'adminActions/fetchServiceStatuses',
      fetchAlertsToClientsStatus: 'forecast/fetchAlertsToClients',
      changeAlertsToClientsStatus: 'forecast/changeAlertsToClients'
    }),
    showServiceStatusConfirmationModal(serviceName) {
      this.processedServiceName = serviceName;
      this.serviceStatusConfirmationModal.show();
    },
    showAlertsToClientsStatusConfirmationModal(){
      this.alertsToClientsConfirmationModal.show();
    },
    async showNotificationMessageConfirmationModal(serviceName) {
      this.processedServiceName = serviceName;
      this.notificationMessageConfirmationModal.show();
    },
    async cancelEdit() {
      this.processedServiceName = null;
      this.initialized = false;
      await this.init();
    },
    async saveErrorsStatusHandler() {
      try {
        await this.changeServiceStatus({
          serviceName: this.processedServiceName,
          hasErrors: this.services[this.processedServiceName].hasErrors,
        });
      } catch (e) {
        console.log(e);
      }
      this.processedServiceName = null;
      await this.init();
    },
    async saveNotificationMessageHandler() {
      try {
        await this.changeServiceNotificationMessage({
          serviceName: this.processedServiceName,
          notificationMessage: this.services[this.processedServiceName].notificationMessage
        });
      } catch (e) {
        console.log(e);
      }
      this.processedServiceName = null;
      this.initialized = false;
      await this.init();
    },
    async saveAlertsToClientsStatusHandler(){
      try {
        await this.changeAlertsToClientsStatus({
          status: this.alertsToClientsStatus.status
        });
      } catch (e) {
        console.log(e);
        this.$toast.error('Failed to toggle switch.\nPlease try again or contact the administrator.');
      }
    },
    async init() {
      await this.fetchServiceStatuses();
      _.forEach(this.serviceStatuses, (serviceStatus) => {
        this.services[serviceStatus.serviceName] = {
          hasErrors: serviceStatus.hasErrors,
          notificationMessage: serviceStatus.notificationMessage
        };
      });
      this.alertsToClientsStatus = await this.fetchAlertsToClientsStatus();
      this.initialized = true;
    }
  }
};
</script>
