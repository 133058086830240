<template>
  <ConfirmationModal
    ref="change-alerts-to-clients-confirmation"
    :onCancelHandler="onCancelHandler"
    :onConfirmHandler="onConfirmHandler"
  >
    <template #content>
      <div
        class="alert alert-warning"
        role="alert"
      >
        <h6>
          <i class="bi bi-exclamation-triangle" />
          Do You really want to change the "Send alerts to clients" value?
        </h6>
      </div>
    </template>
  </ConfirmationModal>
</template>
<script>
import ConfirmationModal from '@/components/common/ConfirmationModal';

export default {
  components: {
    ConfirmationModal
  },
  props: [ 'onCancelHandler', 'onConfirmHandler' ],
  data() {
    return {
      confirmationModal: null
    };
  },
  mounted() {
    this.confirmationModal = this.$refs['change-alerts-to-clients-confirmation'].confirmationModal;
  }
};
</script>
